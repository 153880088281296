.table {
  text-align: center;
  border-collapse: collapse;
}

.table caption {
  margin: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: start;
}

.table th {
  padding: 0.5em 0;
  border-bottom: 1px solid black;
}

.table td {
  padding: 0.5em 0;
}

.table tbody tr:not(:last-child) {
  border-bottom: 1px solid #e7eaf3;
}

.pid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  margin: 0 auto;
}

.table-wrapper {
  display: flex;
  justify-content: center;
  padding: 5em 0;
}

.table-wrapper table {
  border: 1px solid black;
}
