.header {
  text-align: center;
  padding: 0 5%;
  border-bottom: 1px solid #e7eaf3;
}

.header button {
  background-color: transparent;
  border: transparent;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1.5em 1em;
}

.header button a {
  text-decoration: none;
  color: black;
}
